export const isProduction = process.env.NODE_ENV === "production";
const prodBackEndUrl = process.env.PROD_API_BASEURL || ""; 

const api_base_url = process.env.API_BASEURL || 'localhost'
const api_port = process.env.API_PORT || '8080'
const google_map_api_key = process.env.GOOGLE_MAP_API_KEY;

export const backEndBaseURL = isProduction ? prodBackEndUrl : `https://${api_base_url}:${api_port}/`;
export const googleMapApiKey = google_map_api_key;

// export const encryptedPassword = "*ENCRYPTEDPASSWORD*";


